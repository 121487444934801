.logPage {
    display: flex;
    flex-direction: column;
}

.logContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    box-shadow: 0 0 20px -10px;
    margin: 15% 30%;
    border-radius: 10px;
}

.title {
    text-align: center;
    font-size: 30px;
    color: #003250;
    margin-bottom: 50px;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.input {
    width: 60%;
    height: 40px;
    padding: 10px;
    margin: 10px auto;
    border-radius: 10px;
    border: none;
    background: none;
    background-color: rgb(243, 243, 243);
}

.infoAccount {
    color: #003250;
}

.infoLink {
    margin-left: 10px;
    color: #DE3517;
    text-decoration: none;
    border-radius: 5px;
    padding: 2px 2px;
}

.infoLink:hover {
    box-shadow: 0 0 10px -5px;
}

.button {
    height: 40px;
    left: 0;
    width: 40%;
    margin: auto;
    margin-top: 50px;
    color: #DE3517;
    background-color: white;
    border: 3px solid #DE3517;
    border-radius: 20px;
    font-size: 20px;
    font-weight: bold;
    transition: 0.3s;
}

.button:hover {
    background-color: #DE3517;
    color: white;
    cursor: pointer;
    transition: 0.3s;
}

.infoContainer {
    margin: auto;
    padding: 20px;
}

.imgWhy {
    position: fixed;
    left: 10%;
    top: 70%;
    z-index: -1;
}

.imgMessage {
    position: fixed;
    top: 75%;
    right: 10%;
    z-index: -1;
}

.imgQuestion {
    position: fixed;
    top: 35%;
    right: 15%;
    z-index: -1;
}

.imgPhone {
    position: fixed;
    top: 30%;
    left: 15%;
    z-index: -1;
}

.errorStatus {
    color: #DE3517;
}

.password_input{
    background: transparent;
    border: none;
    width: 100%;
    padding: 12px 0px;
}

.forgotten{
    margin: 10px auto;
    text-align: right;
    width: 60%;
    font-size: 12px;
    color: #DE3517;
    text-decoration: none;
}

.sentence{
    font-size: 13px;
}

.resetContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    box-shadow: 0 0 20px -10px;
    margin: 15% 30%;
    border-radius: 10px;
}

.mail_input {
    height: 28px;
    padding: 10px;
    margin-top: 20px;
    border-radius: 10px;
    border: none;
    background-color: rgb(243, 243, 243);
}

.reset_button{
    height: 40px;
    left: 0;
    width: 110px;
    margin-top: 50px;
    color: #DE3517;
    background-color: white;
    border: 3px solid #DE3517;
    border-radius: 10px;
    font-size: 15px;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;
}

.reset_ligne{
    margin-left: auto;
}

.back_login{
    font-size: 12px;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
    text-decoration-color:#DE3517;
    color: black;
    padding-right: 14px;
}

.errorStatusReset {
    color: #DE3517;
    font-size: 14px;
    padding-top: 15px;
}

.successStatusReset {
    color: green;
    font-size: 14px;
    padding-top: 25px;
    margin-bottom: -30px;
}

@media (width < 1024px) {

    .imgWhy {
        visibility: hidden;
    }
    
    .imgMessage {
        visibility: hidden;
    }
    
    .imgQuestion {
        visibility: hidden;
    }
    
    .imgPhone {
        visibility: hidden;
    }

    .logContainer {
        margin: 30% 5%;
    }
    
    .resetContainer {
        margin: 30% 5%;
    }

    .input {
        width: 90%;
    }

    .button {
        font-size: 15px;
        width: 50%;
    }

    .forgotten{
        width: 90%;
    }

}