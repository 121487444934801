.content{
    display: flex;
    justify-content: center;
}
.base{
    padding-top: 12rem;
    padding-bottom: 8rem;
    margin: 0;
}

.title{
    font-size: 32px;
    color: #003250;
    margin-left: 50px;
    margin-right: 50px;
}

.container{
    margin-top: 2.5rem;
    margin-left: 50px;
    margin-right: 50px;

}

.subtitle{
    font-size: 22px;
    font-weight: 700;
    color: #DE3517;
}

.names{
    margin-top: 15px;
    font-weight: 700;
    margin-bottom: 30px;
    font-size: 18px;
}

.names p{
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
}