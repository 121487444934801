.homepage {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 13rem;
    padding-bottom: 10rem;
    margin: 0;
}

.imgLogo {
    width: 5%;
    height: 5%;
    margin-left: 3rem;
}

.appBar {
    height: 100px;
    display: flex;
    position: absolute;
    flex-direction: row;
    text-align: center;
    justify-content: space-between;
    padding: 1rem 0 1.3rem 0;
    width: 100%;
    align-items: center;
    background-color: white;
    z-index: 100;
}

.logButtons {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.link_conseil {
    font-size: 15px;
    font-weight: 600;
    padding: 10px 20px;
    color: #003250;
    margin-right: 1rem;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
    text-decoration-color:#DE3517;
    text-decoration-style: dashed;
}

.buttonLogin {
    font-size: 20px;
    font-weight: 600;
    padding: 10px 20px;
    color: white;
    background-color: #003250;
    border-radius: 50px;
    margin-right: 1rem;
    border: 2px solid #003250;
    transition: 0.3s;
}

.buttonLogin:hover {
    transition: 0.3s;
    background-color: white;
    color: #003250;
    cursor: pointer;
}

.buttonSignin {
    font-size: 20px;
    font-weight: 600;
    padding: 10px 20px;
    color: white;
    background-color: #DE3517;
    border-radius: 50px;
    margin-right: 1rem;
    border: 2px solid #DE3517;
    transition: 0.3s;
}

.buttonSignin:hover {
    transition: 0.3s;
    background-color: white;
    color: #DE3517;
    cursor: pointer;
}

.title {
    text-decoration: none;
    color: #003250;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
    font-size: 50px;
    margin-left: 2rem;
}

@media (width < 448) {
    .title {
        font-size: 20px;
    }
}

.sizedBox {
    padding: 20px;
}

.or {
    padding: 30px;
    color: #003250;
    font-size: 26px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.button {
    padding: 15px 20px;
    margin: 1rem 0 1rem 0;
    border-radius: 30px;
    color: white;
    background-color: #003250;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    border: 2px solid #003250;
    transition: 0.3s;
}

.textWelcome {
    color: #003250;
}

.button:hover {
    color: #003250;
    background-color: white;
    cursor: pointer;
    transition: 0.3s;
}

.imgWhy {
    position: absolute;
    left: 10%;
    top: 70%;
    z-index: -1;
}

.imgMessage {
    position: absolute;
    top: 75%;
    right: 10%;
    z-index: -1;
}

.imgQuestion {
    position: absolute;
    top: 35%;
    right: 15%;
    z-index: -1;
}

.imgPhone {
    position: absolute;
    top: 35%;
    left: 15%;
    z-index: -1;
}

.homeLink {
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.username {
    color: #003250;
    font-size: 20px;
    margin-right: 30px;
    border: 1px solid #003250;
    padding: 10px 20px;
    border-radius: 50px;
}

.homeDescription {
    font-size: 30px;
    color: #003250;
    margin: 0 1rem 1rem 1rem;
}

.redText {
    color: #DE3517;
}

.notConnected {
    border: 1px solid #DE3517;
    border-radius: 30px;
    padding: 2px 5px;
}

.notConnected:hover {
    background-color: #DE3517;
    color: white;
    cursor: pointer;
}

.noDecoration {
    text-decoration: none;
}

@media (width < 1024px) {
    .appBar {
        height: 84px;
        padding: 10px;
        justify-content: space-evenly;
    }

    .title {
        font-size: 20px;
        margin: 0;
    }

    .imgLogo {
        margin: 0;
        margin-right: 5px;
    }

    .logButtons {
        flex-direction: column;
        margin-right: 5px;
    }

    .buttonSignin {
        font-size: 18px;
        padding: 2px 5px;
        margin: 0;
    }

    .buttonLogin {
        font-size: 18px;
        padding: 2px 5px;
        margin-bottom: 5px;
        margin-right: 0;
    }

    .imgWhy {
        visibility: hidden;
    }
    
    .imgMessage {
        visibility: hidden;
    }
    
    .imgQuestion {
        visibility: hidden;
    }
    
    .imgPhone {
        visibility: hidden;
    }

    .username {
        margin: 0 0 10px 0;
        font-size: 18px;
        padding: 5px 10px;
    }
    .link_conseil {
        font-size: 13px;
        text-underline-offset: 2px;
        padding: 2px 5px;
        margin-bottom: 5px;
        margin-right: 0;
    }
}