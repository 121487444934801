.categorieContainer {
    width: 60%;
}

.borderBot {
    border-bottom: solid #bdbdbd 1px;
}

@media (width < 1024px) {
    .categorieContainer {
        width: 90%;
    }
}

.innerCard {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    box-sizing: content-box;
    border-top: solid #bdbdbd 1px;
    border-right: solid #bdbdbd 1px;
    border-left: solid #bdbdbd 1px;
}

.innerCard:hover {
    cursor: pointer;
    background-color: #fafafa;
    overflow: hidden;
}

.cardTitle {
    padding: 25px;
    color: #003250;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    padding-left: 50px;
}

.cardUsername {
    color: red;
}

.arrowDown {
    display: flex;
    height: 100%;
}

.arrowDownImg {
    padding: 33px 20px;
    width: 23px;
    height: 14px;
    align-items: center;
    transition: 0.3s;
}

.arrowUpImg {
    padding: 33px 20px;
    width: 23px;
    height: 14px;
    align-items: center;
    transform: rotate(180deg);
    transition: 0.3s;
}

.greySizedBox {
    background-color: #faf9f8;
    width: 100%;
    height: 10px;
}

.noAnimation {
    visibility: hidden;
    opacity: 0;
    transition: 1s;
}

.animatedQuestions {
    visibility: visible;
    opacity: 1;
    transition: 1s;
}

.questionLinkContainer {
    transition: 1s;
    background-color: #faf9f8;
    width: 100%;
}

.questionLinkContainer:hover {
    box-shadow: inset 0px 0px 2px;
}

.questionLink {
    text-decoration: none;
}

.questionBox {
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-left: 20px;
    color: #003250;
    text-align: left;
}

.questionSpace {
    text-align: left;
    padding: 0 10px;
    color: #DE3517;
}

.questionUser {
    text-align: left;
    color: #DE3517;
}