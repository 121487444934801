.searchBar {
    padding: 10px;
    width: 30%;
    height: 80px;
    background: none;
    border-radius: 50px;
    border: 1px solid #bdbdbd;
    box-sizing: border-box;
    font-size: 26px;
    color: #003250;
    transition: .5s;
}

.searchBar:hover, .searchBar:focus {
    width: 100%;
    background: white;
    border-radius: 10px;
}

.imgSearch {
    position: absolute;
    top: 50%;
    right: calc(50% - 40px);
    transform: translate(-50%,-50%);
    font-size: 26px;
    transition: .2s;
    width: 40px;
    height: 40px;
    z-index: -1;
}

.searchBarContainer {
    position: relative;
    width: 500px;
}

.floatQuestionQuery {
    position: absolute;
    width: 100%;
    margin-top: 10px;
    border-radius: 10px;
}

@media (width <= 1360px) {
    .searchBarContainer {
        position: relative;
        width: 300px;
    }
}

@media (width < 1024px) {
    .hidden {
        display: none;
    }
    .searchBarContainer {
        width: 300px;
    }
}