.questionPage {
    padding: 8rem 0;
    margin: 0;
}

.buttonRetour {
    position: absolute;
    top: 9rem;
    left: 7rem;
    width: 100px;
    height: 50px;
    background-color: #003250;
    border-radius: 20px;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.pageTitle {
    color: #003250;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
    font-size: 70px;
    display: inline;
}

.button {
    padding: 15px 20px;
    border-radius: 30px;
    color: white;
    background-color: #003250;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    display: inline;
    transition: 0.3s;
}

.button:hover {
    color: #003250;
    background-color: white;
    border: 1px solid #003250;
    cursor: pointer;
    transition: 0.3s;
}

.search {
    display: inline;
}

.headerContainer {
    border: solid #707070 1px;
    border-radius: 10px;
    padding: 50px;
    padding-bottom: 20px;
    margin-bottom: 50px;
}

.headerTitle {
    color: #003250;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
}

.headerUsername {
    color: #DE3517;
    font-family: 'Open sans', sans-serif;
    font-size: 30px;
}

.answerTitle {
    color: #003250;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
}

.headerDescription {
    color: #003250;
    font-size: 26px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    padding: 30px 0px;
}

.answersContainer {
    border: solid #707070 1px;
    border-radius: 10px;
    padding: 50px;
}

.contentContainer {
    margin: 100px 50px 50px 100px;
    max-width: 70%;
}

.floatingCategories {
    position: absolute;
    top: 20rem;
    left: calc(70% + 150px);
}

.path {
    display: flex;
    max-width: 70%;
    text-align: center;
    margin-bottom: 30px;
    color: #003250;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    height: fit-content;
}

.pathCategoryTitle {
    height: fit-content;
    text-align: left;
}

.pathImg {
    width: 12px;
    height: 12px;
    padding: 7px 5px;
}

.answerFieldContainer {
    position: relative;
}

.answerFieldImg {
    position: absolute;
    top: 20px;
    left: 10px;
    transform: rotate(-25deg);
    width: 30px;
    height: 30px;
}

.answerField {
    min-height: 3rem;
    width: 50%;
    background-color: rgb(243, 243, 243);
    border-radius: 15px;
    border: none;
    padding: 25px 0 0 50px;
    font-size: 18px;
    font-weight: bold;
}

.littleAnswerField {
    min-height: 1.5rem;
    width: 50%;
    background-color: rgb(243, 243, 243);
    border-radius: 15px;
    border: none;
    padding: 10px 0 0 30px;
    font-size: 16px;
    font-weight: bold;
}

.answerForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
}

.submitButton {
    height: 40px;
    left: 0;
    width: 200px;
    margin-top: 20px;
    color: #DE3517;
    background-color: white;
    border: 2px solid #DE3517;
    border-radius: 20px;
    font-size: 20px;
    font-weight: bold;
    transition: 0.3s;
}

.littleSubmitButton {
    left: 0;
    width: 75px;
    padding: 2px 5px;
    margin-top: 20px;
    color: #DE3517;
    background-color: white;
    border: 1px solid #DE3517;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
    transition: 0.3s;
}

.submitButton:hover {
    background-color: #DE3517;
    color: white;
    cursor: pointer;
    transition: 0.3s;
}

.littleSubmitButton {
    background-color: white;
    color: #DE3517;
    cursor: pointer;
    transition: 0.3s;
}

.littleSubmitButton:hover {
    background-color: #DE3517;
    color: white;
    cursor: pointer;
    transition: 0.3s;
}

.answerField:hover,
.answerField:focus {
    outline: solid 1px #003250;
}

.officialAnswerContainer {
    border: solid #707070 1px;
    border-radius: 10px;
    padding: 10px;
    margin: 30px 0;
}

.hintContainer {
    position: relative;
}

.hintImg {
    position: absolute;
    width: 15px;
}

.hintText {
    padding-left: 30px;
    font-size: 20px;
    color: #003250;
}

.officialAnswerDescription {
    color: #003250;
    padding-top: 10px;
    padding-left: 30px;
    white-space:pre-wrap;
}

.allAnswered {
    padding-left: 30px;
    padding-top: 50px;
}

.answeredContainer {
    position: relative;
}

.answeredImg {
    position: absolute;
    transform: rotate(-10deg);
    width: 25px;
    left: -30px;
    top: 2px;
}

.answeredAuthor {
    padding: 5px 0 0 0;
    color: #DE3517;
    font-size: 20px;
    font-family: sans-serif;
    font-weight: bold;
}

.answeredText {
    padding: 5px 0 10px 30px;
    margin: 5px 0 20px 20px;
    font-size: 18px;
    color: #003250;
    white-space: pre-wrap;
    font-family: sans-serif;
    font-weight: 600;
    border-left: 1px solid black;
}

.answersToAnswer {
    margin-left: 30px;
}

.moreButton {
    color: #DE3517;
    border: 1px solid #DE3517;
    border-radius: 10px;
    width: 80px;
    text-align: center;
    margin-bottom: 20px;
    transition: 0.3s;
}

.moreButton:hover {
    color: white;
    background-color: #DE3517;
    cursor: pointer;
    transition: 0.3s;
}

.floatTitle {
    color: #52595D;
    padding-left: 30px;
}

.floatTitleContainer {
    position: relative;
    margin: 5px;
    padding-left: 10px;
    padding: 10px;
}

.floatTitleContainer:hover {
    box-shadow: 0px 0px 20px -10px;
    border-radius: 10px;
}

.floatCategoryTitle {
    padding-bottom: 15px;
    font-size: 18px;
    color: #003250;
}

.floatTitleImg {
    position: absolute;
}

.questionLink {
    color: #52595D;
    text-decoration: none;
}

.homeLink {
    text-decoration: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button {
    margin-right: 30px;
    margin-left: 30px;
}

.imgLogo {
    width: 5%;
    height: 5%;
    margin-left: 3rem;
}

.title {
    text-decoration: none;
    color: #003250;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
    font-size: 50px;
    margin-left: 2rem;
}

.appBar {
    height: 100px;
    display: flex;
    position: absolute;
    flex-direction: row;
    text-align: center;
    justify-content: space-between;
    padding: 1rem 0 1.3rem 0;
    width: 100%;
    align-items: center;
    background-color: white;
    z-index: 100;
}

.errorStatusReset {
    color: rgb(230, 20, 20);
    font-size: 13px;
    padding-top: 10px;
}

.link_photo{
    margin-bottom: 20px;
    color: #003250;
}

@media (width <= 1360px) {
    .title {
        font-size: 40px;
        margin: 0;
    }

    .imgLogo {
        width: 5%;
        height: 5%;
        margin: 0 1rem;
    }
}

@media (width < 1024px) {
    .headerContainer {
        padding: 30px;
    }

    .buttonRetour {
        top: 6rem;
        left: 1rem;
    }

    .answerField {
        margin-right: 10px;
        width: calc(100% - 50px);
    }

    .answersContainer {
        padding: 30px;
    }
    .contentContainer {
        margin: 10px;
        max-width: 100%;
    }

    .questionPage {
        padding-top: 150px;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
    }

    .floatingCategories {
        position: inherit;
        margin-top: 50px;
    }

    .headerTitle {
        font-size: 30px;
    }

    .answerTitle {
        font-size: 30px;
    }

    .appBar {
        height: 70px;
        padding: 10px;
    }
    
    .title {
        font-size: 20px;
        margin: 0;
    }
    
    .imgLogo {
        margin: 0;
        height: 25px;
        width: 25px;
        margin-right: 5px;
    }

    .button {
        padding: 10px;
        font-size: 15px;
    }
}