.container{
    border-top: solid 1px #efefef;
    height: 55px;
}

.text_center{
    text-align: center;
}

.link{
    color: #696969;
    text-decoration: none;
    line-height: 55px;
    font-size: 13px;
}