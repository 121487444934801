.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(0, 0, 0, 0.25);
    z-index: 3000;
    transition: 1s;
}

.modalOverlay:hover {
    background-color: rgb(0, 0, 0, 0.15);
    transition: 1s;
    cursor: pointer;
}

.modalWrapper {
    position: fixed;
    width: 50%;
    height: 634px;
    top: 10%;
    left: 25%;
    background-color: white;
    border-radius: 20px;
    z-index: 3001;
}

.modalInner {
    margin: auto;
    padding: 2rem 6rem 0 6rem;
    height: 100%;
}

.title {
    color: #003250;
    font-size: 33px;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    text-align: center;
    padding-bottom: 30px;
}

.form {
    display: flex;
    flex-direction: column;
}

.errorStatus {
    color: #DE3517;
}

.button {
    margin: 2rem auto;
    height: fit-content;
    padding: 10px 20px;
    text-align: center;
    background-color: #003250;
    color: white;
    font-size: 20px;
    border-radius: 10px;
}

.button:hover {
    border: 1px solid #003250;
    color: #003250;
    background-color: white;
    cursor: pointer;
}

.successWrapper {
    position: fixed;
    width: 50%;
    height: fit-content;
    top: 25%;
    left: 25%;
    background-color: white;
    border-radius: 20px;
    z-index: 3001;
}

.successContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5%;
}

.success {
    font-size: 30px;
    color: #003250;
    text-align: center;
}

.imgValidate {
    width: 20%;
}

.hint {
    text-align: center;
    color: #003250;
    font-size: 20px;
    font-weight: bold;
}

.hintDiv {
    position: relative;
    margin: 20px 20px 0 20px;
}

.hintImg {
    position: absolute;
    width: 13px;
    left: -20px;
}

.buttonSignin {
    font-size: 20px;
    font-weight: 600;
    padding: 10px 20px;
    margin-top: 3rem;
    color: white;
    background-color: #003250;
    border-radius: 50px;
    border: 2px solid #003250;
    transition: 0.3s;
}

.buttonSignin:hover {
    transition: 0.3s;
    background-color: white;
    color: #003250;
    cursor: pointer;
}

.input {
    width: 330px;
    height: 20px;
    padding: 10px;
    margin: 10px auto;
    border-radius: 10px;
    border: none;
    background-color: rgb(243, 243, 243);
    font-family: Arial, Helvetica, sans-serif;

}

.select {
    width: 350px;
    height: 40px;
    padding: 10px;
    margin: 10px auto;
    border-radius: 10px;
    border: none;
    background-color: rgb(243, 243, 243);
}

@media (width < 994px) {
    .successWrapper {
        width: 90%;
        top: 25%;
        left: 5%;
    }

    .modalWrapper {
        width: 90%;
        left: 5%;
        top: 15%;
    }

    .modalInner {
        padding: 30px;
    }

    .form {
        width: 100%;
    }

    .category {
        height: 30px;
    }

    .title {
        font-size: 25px;
        margin-bottom: 5%;
    }

    .hint {
        font-size: 14px;
    }

    .button {
        height: fit-content;
        margin: 1rem auto;
    }
    .input{
        width: 250px;
    }
    .select{
        width: 270px;
    }
}